@import "../../scss/variables";

.container {
  background-color: $color_white;
  border-radius: 8px;
  padding: 20px;
  max-width: 450px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border: solid 1px $color_border;

  .title {
    color: $color_grey_9;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 700;
    font-size: 13px;
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & button:not(:first-child) {
      margin-left: 10px;
    }
  }

  .expand {
    flex: 1;
    align-items: center;
  }
}
