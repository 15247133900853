@import "../../scss/variables";

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  text-align: center;
  margin: 20px;

  h1 {
    font-family: $font_display;
  }
}

.spinner {
  color: $color_me_primary;
}
