@import "../../scss/variables";

.page {
  background-color: $color_blue_bg;
  min-height: 100vh;
  padding-bottom: 40px;

  .childrenWrapper {
    max-width: 1200px;
    margin: 40px auto 0 auto;

    @media (max-width: 1240px) {
      margin: 40px 20px 0 20px;
    }
  }
}
