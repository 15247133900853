@import "../../../scss/variables";

.spinnerContainer {
  display: flex;
  justify-content: center;
  color: $color_me_primary;
  background-color: $color_blue_bg;
  width: 100vw;
  height: 100vh;
  padding-top: 160px;
}
