@import "../../scss/variables";

.chip {
  height: 24px;
  border-radius: 8px;
  font-size: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding-left: 8px;

  &.color_me_primary {
    background-color: $color_me_primary;
    color: $color_white;
  }
}
