@import "../../scss/variables";

.container {
  background-color: $color_white;
  display: flex;
  width: 100vw;
  height: 100vh;

  .text {
    flex: 1;
    min-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $font_display;

    .handPic {
      margin-bottom: 20px;
    }

    .mainText,
    .mainTextColored {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.25;
    }

    .mainTextColored {
      color: $color_me_primary;
    }

    .secondaryText {
      font-size: 18px;
      margin: 15px 0 25px 0;
      color: $color_grey_7;

      a {
        font-weight: 700;
      }
    }

    .loginButton {
      width: 150px;
      background: $color_me_gradient;
      justify-content: center;
      font-size: 13px;
    }
  }

  .logoutPicWrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    .logoutPic {
      height: 100%;
      width: auto;
    }
  }
}
