$color_me_primary: #b85888;
$color_me_secondary: #db636b;
$color_me_gradient: linear-gradient(to right, $color_me_primary, $color_me_secondary);

$color_white: #ffffff;
$color_grey_E: #eeeeee;
$color_grey_C: #cccccc;
$color_grey_A: #aaaaaa;
$color_grey_9: #999999;
$color_grey_7: #777777;
$color_grey_5: #555555;
$color_light_blue_bg: #f7f9fb;
$color_blue_bg: #eaeef1;
$color_red: #ed382a;
$color_green: #3aa545;
$color_yellow: #fabe53;
$color_blue: #229ace;
$color_text_main: #222222;

$color_error: $color_red;
$color_warn: $color_yellow;
$color_success: $color_green;
$color_info: $color_blue;

$color_error_light: #fdf4f3;
$color_success_light: #f4f9f5;

$color_border: $color_grey_C;
$color_border_hover: $color_grey_A;

$title_card_height: 75px;

$transition_default: all 0.1s ease;

$font_display: "Neue Hans Kendrick", sans-serif;

$zindex_dropdown: 10;
$zindex_modal: 20;
