@import "./scss/fonts";
@import "./scss/variables";
@import "./scss/tooltips";

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color_text_main;
}

button,
input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  box-sizing: border-box;
}

input,
select {
  color: $color_text_main;

  &::placeholder {
    color: rgba($color_text_main, 0.5);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: $color_me_primary;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

* {
  box-sizing: border-box;
}
