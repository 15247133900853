@import "../../scss/variables";
@import "../../scss/mixins";

.form {
  @include landing-form;
  padding: 20px;

  .input {
    margin-bottom: 10px;
  }
}

.linkUnderForm {
  @include smol-heading;
  margin-top: 15px;
  color: $color_white;

  &:hover {
    text-decoration: none;
  }
}
