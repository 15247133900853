@import "../../scss/variables";
@import "../../scss/mixins";

.titleCard,
.mainCard {
  // Temporary while there's only email
  max-width: 800px;
  margin: auto;
}

.titleCard {
  height: $title_card_height;
  font-family: $font_display;
  font-weight: 700;
  font-size: 24px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  .title {
    flex: 1;
  }
}

.spinner {
  margin: 20px auto 30px auto;
}

.mainCard {
  padding: 30px;
  margin-top: -1px;
  display: flex;
  align-items: center;

  .avatarWrapper {
    width: 88px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;

    & > div {
      transform: scale(2);
    }
  }

  .field {
    flex: 1;

    .key {
      @include smol-heading;
      margin-bottom: 5px;
      color: $color_grey_7;
    }

    .value {
      font-size: 14px;
    }
  }
}
