@import "../../../../scss/variables";

.container {
  padding: 5px;

  &.isExpanded .titleBar {
    border-bottom: solid 1px $color_border;
  }
}

.titleBar {
  display: flex;
  margin: 0 5px;
  padding: 10px;
  cursor: pointer;

  .title {
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: 700;
  }
}

.expanderContents {
  display: flex;
  flex-direction: column;
}
