@import "../../scss/variables";

.container {
  display: flex;
  margin: -5px;

  .column {
    display: flex;
    flex-direction: column;
    width: 50%;

    .sourceOverview {
      margin: 5px;
    }
  }
}
