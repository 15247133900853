@import "../../../../scss/variables";
@import "../../../../scss/mixins";

@keyframes highlight {
  from {
    background-color: rgba($color_yellow, 0.75);
  }

  to {
    background-color: $color_light_blue_bg;
  }
}

.container {
  background-color: $color_light_blue_bg;
  border: solid 1px $color_border;
  border-radius: 6px;
}

.container.highlighted {
  & :local {
    animation: highlight 5s;
  }
}

.buttonsBar {
  background-color: rgba($color_me_primary, 0.1);
  border: solid 1px rgba($color_me_primary, 0.25);
  border-radius: 6px;
  padding: 15px;

  .flexWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include gap(10px);

    .alertButtons {
      flex: 1;
      display: flex;
      align-items: center;

      .alertSwitch {
        display: flex;
        align-items: center;
        background-color: $color_me_primary;
        height: 42px;
        border-radius: 21px;
        padding: 0 4px;
        margin-right: 10px;

        .alertButtonWrapper {
          border-radius: 17px;
          margin-right: 4px;
          background-color: transparent;
          transition: $transition_default;
          position: relative;
          padding: 7px;

          &:last-child {
            margin-right: 0;
          }

          &.isSelected {
            background-color: $color_white;
          }

          &.isUnsaved::after {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            content: " ";
            background-color: lighten($color_blue, 10%);
            right: 0;
            top: 0;
          }
        }
      }
    }

    .mainButtons {
      display: flex;

      .mainButton:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

.alertDetail {
  display: flex;
  padding: 15px;
  font-size: 13px;

  .emailSettings .title,
  .filterSettings .title {
    display: flex;
    align-items: center;
    height: 34px;
    font-size: 14px;
    white-space: nowrap;
    margin-right: 20px;
  }

  .emailSettings {
    border-right: solid 1px $color_border;
    display: flex;
    flex-flow: row wrap;
    align-content: flex-start;
    padding-right: 35px;

    .emailsMain {
      max-width: 260px;

      .emailInputWrapper {
        display: flex;
        margin-bottom: 10px;

        .emailInput {
          margin-right: 5px;
          width: 220px;
        }
      }

      .emailChips {
        display: flex;
        flex-flow: row wrap;
        @include gap(5px);

        .errorEmailsMessage {
          color: $color_error;
          margin-left: 15px;
          @include shake;
        }

        .emptyEmailsMessage {
          color: $color_grey_7;
          margin-left: 15px;
        }
      }
    }
  }

  .filterSettings {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-left: 15px;

    .filtersMain {
      color: $color_grey_7;

      .topRow {
        height: 34px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .durationInput {
          width: 48px;
          margin-left: 10px;
          margin-right: 5px;

          & input {
            -moz-appearance: textfield;
          }
          & input::-webkit-outer-spin-button,
          & input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }

        .unitInput {
          @include input-element;
          margin-right: 10px;
          background-color: $color_white;
          padding-right: 5px;
          padding-left: 10px;
        }
      }

      .filterRule {
        display: flex;
        flex-wrap: wrap;
        @include gap(5px);
        margin-bottom: 25px;

        & select {
          @include input-element;
          background-color: $color_white;
          padding: 0 5px 0 10px;
        }

        .pathInput {
          width: 200px;
        }

        .wrapTogether {
          display: flex;
          align-items: center;

          .valueInput {
            width: 200px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
