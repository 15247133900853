@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.emptyMessage {
  color: $color_grey_7;
  margin: 40px auto;
}

.payloads {
  margin: 20px;

  .header {
    display: flex;
    @include smol-heading;
    margin-bottom: 15px;

    .expand {
      flex: 1;
    }
  }

  .table {
    display: flex;
    font-size: 12px;

    .payloadColumn {
      flex: 1;
      min-width: 0; // 😠 https://css-tricks.com/flexbox-truncated-text/

      .payload {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .buttonColumn {
      .showPayloadButton {
        @include show-payload-button;
        display: block;
        margin-left: 20px;
        margin-right: 20px;
        font-size: inherit;
      }
    }

    .timestampColumn {
      text-align: right;
    }

    .payload,
    .showPayloadButton,
    .timestamp {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .showMoreButton {
    @include show-payload-button;
    font-size: 12px;
    margin-top: 15px;

    .caretIcon {
      margin-left: 5px;
    }
  }
}
