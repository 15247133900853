@keyframes animation-rotate {
  0% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(315deg);
  }
}

.wrapper {
  &.size_xs {
    width: 16px;
    height: 16px;
  }

  &.size_sm {
    width: 28px;
    height: 28px;
  }

  &.size_md {
    width: 40px;
    height: 40px;
  }

  &.size_lg {
    width: 60px;
    height: 60px;
  }
}

.spinner {
  animation: animation-rotate 975ms cubic-bezier(0.25, 0.35, 0.5, 0.95) infinite;
}
