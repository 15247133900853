@import "../../scss/variables";

@mixin color-styles($color-var, $class-name) {
  &.#{$class-name} {
    &:focus:not(.textOnly) {
      box-shadow: 0 0 0 2px rgba($color-var, 0.4);
    }

    &:not(.secondaryButton):not(.textOnly):not(.disabled) {
      background-color: $color-var;

      &:hover {
        background-color: darken($color-var, 5%);
      }

      &:active {
        background-color: darken($color-var, 10%);
      }
    }

    &.secondaryButton:not(.disabled) {
      color: $color-var;
      border: solid 1px rgba($color-var, 0.5);

      &:hover {
        border-color: rgba($color-var, 0.75);
        color: darken($color-var, 5%);
      }

      &:active {
        border-color: $color-var;
        color: darken($color-var, 10%);
      }
    }

    &.textOnly:not(.faded):not(.disabled) {
      color: $color-var;

      &:hover,
      &:focus {
        color: darken($color-var, 7.5%);
      }

      &:active {
        color: darken($color-var, 15%);
      }
    }

    &.textOnly.faded:not(.disabled) {
      color: rgba($color-var, 0.6);

      &:hover,
      &:focus {
        color: $color-var;
      }
    }
  }
}

.button {
  border-radius: 8px;
  text-transform: uppercase;
  white-space: nowrap;
  font-family: $font_display;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.1em;
  cursor: pointer;
  padding: 0 12px;
  color: $color_white;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: all 0.1s ease;
  transition-property: color, background-position, background-color, border-color, box-shadow,
    opacity;

  &:hover:not(.textOnly):not(.disabled):not(:focus) {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }

  &.withIcon .childrenWrapper {
    margin-left: 8px;
  }

  &.withIcon.iconOnRight {
    flex-direction: row-reverse;

    .childrenWrapper {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  &.isLoading {
    position: relative;

    &:not(.disabled) {
      cursor: wait;
    }

    .childrenWrapper,
    .icon {
      visibility: hidden;
    }

    .spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.disabled {
    cursor: not-allowed;

    &.secondaryButton {
      color: $color_grey_C;
      border: solid 1px $color_grey_C;
    }

    &.textOnly {
      color: $color_grey_C;
    }

    &:not(.secondaryButton):not(.textOnly) {
      background-color: $color_grey_C;
    }
  }

  &.fullWidth {
    width: 100%;
    justify-content: center;
  }

  &.secondaryButton {
    background-color: $color_white;
  }

  &.textOnly {
    background-color: transparent;
    height: 20px;

    &.size_lg {
      height: 24px;

      &.iconOnly {
        width: 24px;
      }
    }

    &.size_xl {
      height: 28px;

      &.iconOnly {
        width: 28px;
      }
    }

    &.iconOnly {
      width: 20px;
    }

    &:not(.iconOnly) {
      padding: 0 2px;
    }
  }

  // there is no var name interpolation in sass, so I have to pass both var name and class name
  @include color-styles($color_me_primary, color_me_primary);
  @include color-styles($color_grey_E, color_grey_E);
  @include color-styles($color_white, color_white);
  @include color-styles($color_grey_C, color_grey_C);
  @include color-styles($color_grey_A, color_grey_A);
  @include color-styles($color_red, color_red);
  @include color-styles($color_green, color_green);
  @include color-styles($color_yellow, color_yellow);
  @include color-styles($color_blue, color_blue);
  @include color-styles($color_grey_9, color_grey_9);
  @include color-styles($color_grey_7, color_grey_7);
  @include color-styles($color_grey_5, color_grey_5);
  @include color-styles($color_text_main, color_text_main);

  &.iconOnly {
    padding: 0;
    justify-content: center;
    flex-shrink: 0;
  }

  &.size_sm:not(.textOnly) {
    height: 28px;
    padding: 0 10px;

    &.iconOnly {
      width: 28px;
    }
  }

  &.size_md:not(.textOnly) {
    height: 34px;

    &.iconOnly {
      width: 34px;
    }
  }

  &.size_lg {
    font-size: 13px;

    &.iconOnly {
      font-size: 14px;
    }

    &:not(.textOnly) {
      height: 44px;

      &.iconOnly {
        width: 44px;
      }
    }
  }

  &.size_xl {
    font-size: 13px;

    &.iconOnly {
      font-size: 16px;
    }

    &:not(.textOnly) {
      height: 52px;
      padding: 0 16px;

      &.iconOnly {
        width: 52px;
      }
    }
  }
}
