@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.container {
  background-color: $color_white;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border: solid 1px $color_border;

  .header {
    display: flex;
    margin-bottom: 20px;

    .title {
      flex: 1;
      @include smol-heading;
      font-size: 13px;
      color: $color_grey_9;
    }
  }

  .oldPasswordInput {
    margin-bottom: 5px;
  }

  .buttons {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .expand {
      flex: 1;
    }
  }
}
