@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.container {
  padding: 10px;
  font-size: 12px;
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;

  .title {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;

    .caretIcon {
      margin-left: 5px;
      transition: $transition_default;
      font-size: 16px;
    }
  }
}

.eventRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .expand {
    flex: 1;

    .showPayloadButton {
      @include show-payload-button;
    }
  }
}

.showMoreButton {
  margin-bottom: 10px;
  @include show-payload-button;

  .caretIcon {
    margin-left: 3px;
  }
}
