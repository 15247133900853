@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.container {
}

.titleCard {
  height: $title_card_height;
  padding: 0 20px;
  display: flex;
  align-items: center;

  & > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 15px;
  }

  .titleWrapper {
    flex: 1;
  }

  .title {
    font-size: 21px;
    font-family: $font_display;
    margin: 0;
  }

  .path {
    color: $color_grey_9;
    font-size: 14px;
  }

  .emptyMessage {
    margin-right: 20px;
    color: $color_grey_7;
  }
}

.mainCard {
  padding: 10px 10px 0 10px;
  margin-top: -1px;

  .eventTypesHeader {
    display: flex;
    margin: 10px;
    margin-top: 20px;
    @include smol-heading;

    .expand {
      flex: 1;
    }
  }

  .eventTypeRow:not(:last-child) {
    border-bottom: solid 1px $color_border;
  }
}
