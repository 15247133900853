@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.container {
  background-color: $color_white;
  border-radius: 8px;
  padding: 20px;
  width: 600px;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border: solid 1px $color_border;

  .header {
    display: flex;

    .title {
      flex: 1;
      @include smol-heading;
      font-size: 13px;
      color: $color_grey_9;
    }

    .checkboxLabel {
      font-size: 14px;
    }
  }

  .row {
    padding: 10px 0 5px 0;
    display: flex;
    align-items: center;

    .input {
      margin-right: 10px;
      flex: 1;
    }

    &:not(:nth-of-type(2)) {
      border-top: solid 1px $color_border;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .expand {
      flex: 1;
    }
  }
}
