@import "../../scss/variables";
@import "../../scss/mixins";

.container {
  position: relative;

  &.size_lg {
    & .input {
      height: 44px;
      font-size: 15px;
    }

    & .eyeButton {
      right: 10px;
    }

    .flexWrapper {
      .labelWrapper {
        .input {
          height: 44px;
          font-size: 15px;
        }

        .eyeButton {
          right: 10px;
          bottom: 8px;
        }
      }
    }
  }
}

.flexWrapper {
  display: flex;

  .labelWrapper {
    position: relative;
    flex: 1;

    .label {
      color: $color_grey_9;
      font-size: 12px;
      margin-bottom: 4px;
    }

    .input {
      @include input-element;
      width: 100%;

      &.hasError {
        @include shake;
        border-color: $color_error;

        &:focus {
          box-shadow: 0 0 0 2px rgba($color_error, 0.4);
        }
      }
    }

    .eyeButton {
      position: absolute;
      right: 5px;
      bottom: 2px;
    }
  }

  .passwordStrength {
    height: 34px;
    width: 34px;
    margin-left: 10px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color_white;
    font-size: 8px;
    border-radius: 6px;

    .container.size_lg & {
      height: 44px;
      width: 44px;
    }

    &.weak {
      background-color: $color_red;
    }

    &.fine {
      background-color: $color_yellow;
    }

    &.strong {
      background-color: $color_green;
    }

    &.isEmpty {
      background-color: $color_grey_C;
    }

    img {
      height: 16px;
      width: auto;
      max-width: 24px;
    }
  }
}

.errorMessage {
  color: $color_error;
  font-size: 12px;
  position: absolute;
  bottom: -18px;

  .container.hasSpacer & {
    bottom: -3px;
  }
}

.spacer {
  min-height: 15px;
}
