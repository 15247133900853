@import "../../scss/variables";
@import "../../scss/mixins";

.titleCard {
  height: $title_card_height;
  font-family: $font_display;
  font-weight: 700;
  font-size: 24px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.spinner {
  margin: 20px auto 30px auto;
}

.emptyMessage {
  color: $color_grey_7;
  margin: 20px auto 30px auto;
  max-width: 500px;
}

.mainCard {
  padding: 10px;
  padding-bottom: 0;
  margin-top: -1px;

  .alertsTable {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        @include smol-heading;
        padding: 20px 10px;
        text-align: left;
        position: sticky;
        top: 0;
        background-color: $color-white;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: solid 1px $color_border;
        }

        &:hover {
          background-color: rgba($color_me_primary, 0.1);
        }

        font-size: 14px;
        color: $color_grey_7;

        td {
          padding: 20px 10px;
          vertical-align: top;
        }

        .source {
          .icon {
            font-size: 10px;
            color: $color_grey_9;
            margin-bottom: 1px;
          }
        }

        .eventType {
          font-size: 16px;
          color: $color_text_main;
          font-weight: 700;
          margin-top: 3px;
        }

        .alertConditions {
          font-size: 12px;

          & > div:not(:first-child) {
            margin-top: 12px;
            margin-left: 10px;
          }

          span {
            font-weight: 600;
            font-family: monospace;
            color: $color_me_primary;
            padding: 4px 6px;
            background-color: $color_grey_E;
            border-radius: 8px;
            // border: solid 1px $color_border;
            // margin: 1px;
          }
        }

        .emails {
          & > div:not(:first-child) {
            margin-top: 10px;
          }
        }

        .buttons {
          display: flex;
          justify-content: flex-end;

          .rowButton:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
