@import "../../scss/variables";

.container {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyMessage {
  color: $color_grey_7;
}
