@import "../../scss/variables";

.container {
  margin: 20px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .coloredBg {
    padding: 20px;
    background-color: rgba($color_me_primary, 0.2);
  }
}

.primaryColor {
  color: $color_me_primary;
}

.spinners {
  display: flex;
  gap: 20px;
  align-items: center;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}
