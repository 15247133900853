@import "./variables";

$bg_color: $color_grey_9;

* [data-tooltip] {
  position: relative;

  // beak
  &::before {
    content: " ";
    width: 0;
    height: 0;
    border-left: solid 5px $bg_color;
    border-top: solid 5px $bg_color;
    border-right: solid 5px transparent;
    border-bottom: solid 5px transparent;
    transform: rotate(45deg) translate(-50%);
  }

  &::after {
    content: attr(data-tooltip);
    pointer-events: none;
    background-color: $bg_color;
    color: $color_white;
    font-family: "Inter", sans-serif;
    text-transform: none;
    letter-spacing: 0;
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    padding: 6px 8px;
    transform: translateX(-50%);
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  &::before,
  &::after {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: calc(100% + 8px);
    transition: all 0.1s ease 0s;
    opacity: 0;
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    transition: all 0.1s ease 0.4s;
  }
}
