@import "../../scss/variables";

.avatar {
  width: 44px;
  height: 44px;
  margin-right: 10px;
  background-color: $color-white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .initials {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: $color_me_primary;

    &.small {
      font-size: 12px;
    }
  }
}
