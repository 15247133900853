@import "../../scss/variables";
@import "../../scss/mixins";

.container {
  & a,
  & button {
    outline: none;
  }
}

.primaryBar {
  height: 72px;
  display: flex;
  justify-content: center;
  background: $color_me_gradient;

  .primaryBarInner {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 20px;
    flex: 1;

    .left,
    .right {
      flex: 1;
    }

    .left {
      display: flex;
      justify-content: flex-start;
    }

    .titleWrapper {
      display: flex;
      align-items: center;

      .meiroLogo {
        width: 56px;
        height: 56px;
      }

      .clientLogo {
        width: 40px;
        height: 40px;
        margin-left: -6px;
      }

      .title {
        font-size: 12px;
        font-family: $font_display;
        margin-left: 30px;
        text-transform: uppercase;
        color: $color_white;
        letter-spacing: 0.15em;
      }
    }

    .middle .primaryLink {
      font-size: 15px;
      font-family: $font_display;
      font-weight: 700;
      text-transform: uppercase;
      color: $color_white;
      letter-spacing: 0.1em;
      opacity: 0.6;
      transition: opacity 0.1s ease;
      display: flex;
      align-items: center;
      text-decoration: none;

      &:hover,
      &:focus,
      &.active {
        opacity: 1;
      }

      .iconWrapper {
        width: 36px;
        height: 36px;
        background-color: $color_white;
        color: $color_me_primary;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .adminLink {
        font-size: 16px;
        color: rgba($color_white, 0.6);
        transition: color 0.1s ease;
        margin-right: 15px;

        & > div {
          width: 34px;
          height: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover,
        &:focus,
        &.active {
          color: $color_white;
        }
      }

      .userMenuContainer {
        position: relative;

        .userMenuButton {
          display: flex;
          align-items: center;
          border: none;
          background-color: transparent;
          color: rgba($color_white, 0.6);
          padding: 5px;
          transition: color 0.1s ease;
          cursor: pointer;

          &:hover,
          &:focus,
          &.isOpen {
            color: $color_white;
          }
        }

        .clickCatcher {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          z-index: $zindex_dropdown;
        }

        .userMenu {
          position: absolute;
          top: calc(100% + 5px);
          right: -10px;
          background-color: $color_white;
          border-radius: 8px;
          z-index: $zindex_dropdown + 1;
          color: $color_grey_7;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);

          &::before {
            content: " ";
            border-left: solid 4px $color_white;
            border-top: solid 4px $color_white;
            border-right: solid 4px transparent;
            border-bottom: solid 4px transparent;
            width: 0;
            height: 0;
            position: absolute;
            transform: rotate(45deg) translate(-50%);
            top: -1px;
            right: 12px;
          }

          .links {
            margin: 15px;

            .link {
              margin: 5px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: $color_grey_7;
              text-decoration: none;
              transition: color 0.1s ease;
              padding: 5px;
              cursor: pointer;
              border: none;
              background-color: transparent;
              text-align: left;
              font-size: 16px;
              width: 100%;

              & > span {
                flex: 1;
              }

              &:hover,
              &:focus {
                color: $color_me_primary;
              }

              .icon {
                margin-right: 10px;
              }
            }
          }

          .aboutWrapper {
            background-color: $color_blue_bg;
            margin: 5px;
            border-radius: 8px;
            white-space: nowrap;
            overflow: auto; // prevent margin-collapse

            .about {
              border: solid 1px $color_border;
              background-color: $color_white;
              border-radius: 8px;
              height: 34px;
              padding: 0 10px;
              display: flex;
              align-items: center;
              gap: 10px;
              margin: 5px;
              font-size: 12px;

              .icon {
                font-size: 14px;
                flex: 0 0 14px;
              }

              .text {
                flex: 1;
              }
            }
          }
        }
      }
    }
  }
}

.secondaryBar {
  height: 40px;
  background-color: $color_white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color_grey_9;
  font-size: 12px;

  .secondaryLink {
    text-decoration: none;
    @include smol-heading;
    font-size: 12px;
    color: $color_grey_9;
    margin: 5px;
    padding: 3px;

    &.active {
      color: $color_me_primary;
    }
  }
}

// the little white beak under an active navlink
.primaryLink.active,
.adminLink.active {
  position: relative;

  &::after {
    position: absolute;
    content: " ";
    width: 0;
    height: 0;
    border-left: solid 5px $color_white;
    border-top: solid 5px $color_white;
    border-right: solid 5px transparent;
    border-bottom: solid 5px transparent;
    transform: rotate(45deg) translate(-50%);
    left: 50%;
    top: calc(50% + 35px);
    pointer-events: none;
    cursor: default;
  }
}
