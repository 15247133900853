@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
    url("../fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
    url("../fonts/Inter-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Medium.woff2") format("woff2"),
    url("../fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Light.woff2") format("woff2"),
    url("../fonts/Inter-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("../fonts/NeueHansKendrick-Regular.woff2") format("woff2"),
    url("../fonts/NeueHansKendrick-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("../fonts/NeueHansKendrick-Bold.woff2") format("woff2"),
    url("../fonts/NeueHansKendrick-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neue Hans Kendrick";
  src: url("../fonts/NeueHansKendrick-Medium.woff2") format("woff2"),
    url("../fonts/NeueHansKendrick-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
