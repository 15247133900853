@import "../../scss/variables";

.page {
  display: flex;
  height: 100vh;

  .formSection {
    flex-shrink: 0;
    width: 50%;
    max-width: 480px;
    min-width: 360px;
    background: linear-gradient(195.88deg, $color_me_primary 0%, $color_me_secondary 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .meiroSymbol {
      width: 89px;
      height: 89px;
      margin-bottom: 20px;
    }
  }

  .landingSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    text-align: left;
    padding: 40px;

    h1 {
      font-size: 48px;
      line-height: 58px;
      margin: 0;
      color: $color_me_primary;
      font-family: $font_display;
    }

    h2 {
      font-weight: 500;
      font-size: 40px;
      margin: 0;
      font-family: $font_display;
    }
  }
}
