@import "../../scss/variables";
@import "../../scss/mixins";

.payloadModal {
  background-color: $color_white;
  border-radius: 8px;
  padding: 30px;
  padding-top: 20px;
  margin: 20px;
  max-width: 720px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .title {
      flex: 1;
      @include smol-heading;
      font-size: 13px;
      height: 34px;
      display: flex;
      align-items: center;
    }

    .closeIcon {
      margin-left: 10px;
    }
  }

  .payload {
    border-radius: 6px;
    background-color: $color_light_blue_bg;
    border: solid 1px $color_border;
    white-space: pre;
    max-height: 400px;
    font-family: monospace;
    font-size: 12px;
    padding: 10px;
    overflow-y: scroll;
  }
}
