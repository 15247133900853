@import "../../scss/variables";

.card {
  border: 1px solid $color_border;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: $color_white;

  &.color_light_blue_bg {
    background-color: $color_light_blue_bg;
  }
}
