@import "../../scss/variables";

.container {
  display: flex;
  align-items: center;
  height: 34px;
  padding-right: 10px;
  min-width: 160px;

  .button {
    background-color: $color_grey_C;
    color: $color_white;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 5px;
    flex-shrink: 0;
    font-size: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.05s ease;

    &:hover,
    &:focus {
      background-color: darken($color_grey_C, 5%);
    }
  }

  .saveButton {
    @extend .button;
    background-color: $color_me_primary;
    margin-left: -12px;
    z-index: 2;

    &:hover,
    &:focus {
      background-color: darken($color_me_primary, 5%);
    }
  }
}
