@import "../../scss/variables";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color_border;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  background-color: $color_white;
  padding: 80px 40px;
  flex-wrap: wrap;

  .main {
    display: flex;
    align-items: center;

    .logo {
      width: 100px;
      height: 100px;
    }

    font-size: 110px;
    font-weight: 700;
    color: $color_me_primary;
  }

  .message {
    font-family: $font_display;
    margin: 20px 40px;

    .title {
      margin: 0;
      margin-bottom: 12px;
      font-size: 32px;
    }

    .subtitle {
      font-size: 18px;
    }
  }
}
