/*
 * Sass form input placeholder css styling
 *
 * Usage:
 *	@include placeholder {
 *   	font-style:italic;
 *   	color: white;
 *   	font-weight:100;
	*	}
 */
@mixin placeholder {
  ::-webkit-input-placeholder {
    @content;
  }
  :-moz-placeholder {
    @content;
  }
  ::-moz-placeholder {
    @content;
  }
  :-ms-input-placeholder {
    @content;
  }
}

@mixin smol-heading {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.1em;
}

@mixin show-payload-button {
  text-decoration: underline;
  background-color: transparent;
  transition: $transition_default;
  cursor: pointer;
  border: none;
  padding: 0;

  &:focus {
    outline: $color_grey_C solid 1px;
  }

  &:hover {
    color: $color_grey_5;
  }
}

@mixin input-element {
  border: solid 1px rgba($color_grey_7, 0.5);
  border-radius: 8px;
  padding: 0 10px;
  height: 34px;
  outline: none;
  transition: $transition_default;
  transition-property: border-color box-shadow;
  font-size: 13px;

  &:hover {
    border-color: rgba($color_grey_7, 1);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($color_grey_7, 0.4);
  }
}

@mixin gap($gap) {
  // simulates gap property
  display: flex;
  margin-top: -#{$gap};
  margin-left: -#{$gap};

  & > * {
    margin-top: #{$gap};
    margin-left: #{$gap};
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate(-1px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate(2px);
  }

  30%,
  50%,
  70% {
    transform: translate(-2px);
  }
}

@mixin shake {
  transform: translate(0);
  animation: shake 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97);
}

@mixin landing-form {
  width: 320px;
  background: $color_white;
  border-radius: 8px;

  h1 {
    font-size: 28px;
    line-height: 1.15;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    font-family: $font_display;
  }
}
