@import "../../scss/variables";
@import "../../scss/mixins";

.linkUnderForm {
  @include smol-heading;
  margin-top: 15px;
  color: $color_white;

  &:hover {
    text-decoration: none;
  }
}

.messageContainer {
  @include landing-form;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  svg {
    color: $color_me_primary;
  }

  .message {
    text-align: center;
  }
}
