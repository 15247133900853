@import "../../scss/variables";
@import "../../scss/mixins";

.container {
  & > * {
    margin-bottom: 10px;
  }
}

.titleCard {
  display: flex;
  height: $title_card_height;
  padding: 0 20px;
  align-items: center;

  .title {
    flex: 1;
    font-family: $font_display;
    font-weight: 700;
    font-size: 24px;
  }

  .lastTimestamp {
    text-align: right;

    .label {
      @include smol-heading;
    }

    .value {
      font-size: 14px;
      line-height: 1.5;
      color: $color_grey_9;
    }
  }
}

.chartCard {
  padding: 20px;
}

.spinner {
  margin: 40px auto;
}

.emptyMessageCard {
  padding: 40px;
  display: flex;
  justify-content: center;
  color: $color_grey_7;
}
