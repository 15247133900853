@import "../scss/variables";

.Toastify__toast {
  border-radius: 8px;
  white-space: pre-wrap;
  padding: 15px;
}

.Toastify__toast--info {
  background: $color_white;
  border: solid 1px $color_me_primary;
  color: $color_me_primary;

  .Toastify__close-button {
    color: $color_me_primary;
  }
}

.Toastify__toast--success {
  background: $color_success_light;
  border: solid 1px $color_success;
  color: $color_success;

  .Toastify__close-button {
    color: $color_success;
  }
}

.Toastify__toast--error {
  background: $color_error_light;
  border: solid 1px $color_error;
  color: $color_error;

  .Toastify__close-button {
    color: $color_error;
  }
}

.Toastify__toast-body {
  display: flex;
  align-items: center;
  font-size: 12px;

  .toastIcon {
    margin-right: 10px;
    font-size: 16px;
  }
}

.Toastify__close-button {
  align-self: unset;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
