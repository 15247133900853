@import "../../scss/variables";

.clickCatcher {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $zindex_modal;
  background-color: rgba(0, 0, 0, 0.4);
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $zindex_modal + 1;
}
