@import "../../scss/variables";
@import "../../scss/mixins";

.titleCard,
.mainCard {
  // Temporary while there's only one column
  max-width: 800px;
  margin: auto;
}

.titleCard {
  height: $title_card_height;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-family: $font_display;
    font-size: 24px;
    font-weight: 700;
  }

  .search {
    position: relative;

    .searchInput {
      @include input-element;
      height: 52px;
      font-size: 16px;
    }

    .clearButton {
      position: absolute;
      right: 8px;
      top: 11px;
      width: 30px;
      height: 30px;
    }
  }
}

.spinner {
  margin: 20px auto 30px auto;
}

.emptyMessage {
  color: $color_grey_7;
  margin: 20px auto 30px auto;
  max-width: 500px;
}

.mainCard {
  padding: 10px;
  padding-bottom: 0;
  margin-top: -1px;

  .usersTable {
    width: 100%;
    border-collapse: collapse;

    thead {
      th {
        @include smol-heading;
        padding: 20px 10px;
        text-align: left;
        position: sticky;
        top: 0;
        background-color: $color-white;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: solid 1px $color_border;
        }

        &:hover {
          background-color: rgba($color_me_primary, 0.1);
        }

        font-size: 14px;
        color: $color_grey_7;

        td {
          padding: 20px 10px;
        }

        .buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .rowButton:last-child {
            margin-left: 10px;
          }

          .rowButton.hidden {
            visibility: hidden;
          }
        }
      }
    }
  }
}
