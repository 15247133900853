@import "../../../../scss/variables";
@import "../../../../scss/mixins";

.form {
  @include landing-form;

  .fieldsSection {
    padding: 20px;

    .input {
      margin-top: 10px;
    }
  }

  .buttonsSection {
    padding: 20px;
    background-color: $color_light_blue_bg;
    border-top: solid 1px $color_border;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .oktaButton {
    margin-top: 15px;
    background-position: 0% 0%;
    background-size: 100% 200%;

    &:not(:disabled) {
      background-color: #222222 !important;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%) !important;

      &:hover {
        background-position: 0% 100% !important;
      }

      &:focus {
        box-shadow: 0 0 0 2px rgba(#222222, 0.5) !important;
      }

    }

    img {
      width: 41px;
      height: auto;
      margin-bottom: -1px;
    }
  }

  .agreement {
    display: flex;
    align-items: center;
    margin-top: 15px;
    font-size: 12px;
    line-height: 1.5;
    color: $color_grey_7;

    input {
      margin-right: 10px;
    }
  }
}
