@import "../../scss/variables";
@import "../../scss/mixins";

.form {
  @include landing-form;
  padding: 20px;

  h1 {
    margin-bottom: 10px;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 10px;

    span {
      font-weight: 700;
    }
  }

  .instruction {
    margin-top: 20px;
  }

  .input {
    margin: 10px 0;
  }
}

.linkUnderForm {
  @include smol-heading;
  margin-top: 15px;
  color: $color_white;

  &:hover {
    text-decoration: none;
  }
}

.background {
  background: $color_me_gradient;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.meiroSymbol {
  width: 89px;
  height: 89px;
  margin-bottom: 20px;
}

.resendButton {
  margin-top: 20px;
}
